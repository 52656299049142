import React, {useContext, useEffect} from "react";
import {Context as AuthContext} from '../context/AuthContext'
// import {Context as SocketContext} from '../context/SocketContext'
import { Route, Redirect } from 'react-router-dom'
import SideNavbar from './SideNavbar'
import  {LeftSideMenu, TopMenu } from './Menu'
import UserAlert from './UserAlert'
import MessageModal from './MessageModal'
import FooterPlayer from './FooterPlayer'

// It checks if the user is authenticated, if they are,
// it renders the "component" prop. If not, it redirects
// the user to /login.

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {state, tryLocalSignin} = useContext(AuthContext);
  // const socketContext = useContext(SocketContext);
  // const socketState = socketContext.state.socket;

  useEffect(() => {
    // socketContext.reset()
    tryLocalSignin();
  }, [])

  if(state.signedIn === undefined) {
    return null;
  } else {
    return (
      <Route {...rest} render={(props) => (
        state.signedIn === true
          ? <>
              <LeftSideMenu/>
              <div className="main-content">
                <UserAlert/>
                <MessageModal/>
                <TopMenu/>
                <Component {...props} />
                <FooterPlayer/>
              </div>
            </>
          : <Redirect to='/signin' />
      )} />
    )
  }
}


export default PrivateRoute