import React, {useEffect, useState, useContext} from "react";
import queryString from 'query-string'
import { Spinner } from 'reactstrap';
import justcastApi from '../../api/justcast'

export default function ConvertkitCallback(props) {
  const values = queryString.parse(props.location.search);
  const { code, state } = values;

  useEffect(() => {
    if(code && state) {
      justcastApi.put(`/v2/kit_connection_requests/${state}.json`, {
        auth_code: code
      })
      .then((res) => {
        const { callback_url } = res.data || {};
        if(callback_url) {
          window.location = callback_url;
        }
      })
      .catch((err) => {
        // could be code has expired
        console.log(err)
      })
    }
  }, [code, state])

  if(code && state) {
    return <Spinner/>
  }

  return null;
}
