import React, {useState} from 'react'
import { useForm, Controller } from "react-hook-form";
import { Collapse, Button, Card, CardBody, CardFooter, Badge, InputGroup, Label, Form, FormGroup, Row, Col, FormText, CardHeader } from 'reactstrap';
import Select from 'react-select'

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

const actionOptions = [
  {label: "Drip an episode every", value: "sequential_feed", id: "sequential_feed"},
  {label: "Episode expiration", value: "episode_expires_after", id: "episode_expires_after"},
  {label: "Podcast expiration", value: "podcast_expires_after", id: "podcast_expires_after"},
  {label: "Allow full access after", value: "podcast_full_access_after", id: "podcast_full_access_after"},
  {label: "Drop a batch of episodes every", value: "batch_feed", id: "batch_feed"},
  {label: "Batch of episodes expiration", value: "batch_expires_after", id: "batch_expires_after"},
]

const actionUnits = [
  {label: "Hours", value: 'hours', id: 'hours'},
  {label: "Days", value: 'days', id: 'days'},
  {label: "Weeks", value: 'weeks', id: 'weeks'},
  {label: "Months", value: 'months', id: 'months'},
]

export default function NewActionForm({initiatorOptions, handleCreate}) {
  const { control, register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    handleCreate(data)
  }

  return (
    <Card>
      <CardHeader>New Action</CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Controller
                  name="action_name"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <Select
                    {...field}
                    styles={customStyles}
                    options={actionOptions}
                  />}
                />
                {errors.action_name && <FormText color="warning">Can't be empty</FormText>}
                {/* {formErrors?.action && <FormText color="warning">{formErrors?.action}</FormText>} */}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <input
                  type="number"
                  className="form-control"
                  placeholder="2" {...register("action_value", {required: true})} />
                {errors.action_value && <FormText color="warning">Can't be empty</FormText>}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Controller
                  name="action_unit"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <Select
                    {...field}
                    styles={customStyles}
                    options={actionUnits}
                  />}
                />
                {errors.action_unit && <FormText color="warning">Can't be empty</FormText>}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="initiator_id">Applies to</Label>
                <Controller
                  name="initiator"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => <Select
                    {...field}
                    styles={customStyles}
                    options={initiatorOptions}
                  />}
                />
                {errors.initiator && <FormText color="warning">Can't be empty</FormText>}
                {/* {formErrors?.action && <FormText color="warning">{formErrors?.action}</FormText>} */}
              </FormGroup>
            </Col>
          </Row>
          <hr/>
          <div style={{display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
            <Button color="primary">Save</Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  )
}
