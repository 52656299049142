import createDataContext from './createDataContext';

const userMenuItems = [
  {name: 'Connection', link: '/connections', id: 'connections', iconClassName: 'fe fe-cloud'},
  {name: 'Upgrade', link: '/plans', id: 'plans', iconClassName: 'fe fe-arrow-up-circle'},
  // {name: 'Payment method', link: '/update_credit_card', id: 'update_credit_card', iconClassName: 'fe fe-credit-card'},
  {name: 'Billing', link: '/billing', id: 'billing', iconClassName: 'fe fe-inbox'},
  {name: 'Affiliates', link: '/affiliates', id: 'affiliates', iconClassName: 'fe fe-dollar-sign'},
  {name: 'Balance', link: '/stripe_account_balance', id: 'stripe_account_balance', iconClassName: 'fe fe-trending-up'},
  {name: 'Change Password', link: '/change_password', id: 'change_password', iconClassName: 'fe fe-unlock'},
  // {name: 'Sign out', link: '/sign_out', id: 'sign_out', iconClassName: 'fe fe-log-out'},
]

const leftSideItems = [
  {name: 'Podcasts', link: '/', id: 'home'},
  {name: 'New Podcast', link: '/shows/new', id: 'new_podcast'},
  {name: 'Connections', link: '/connections', id: 'connections'},
  {name: 'Sender Authentication', link: '/sender_authentications', id: 'sender_authentications'},
  // {name: "Dynamic Contents", link: '/dynamic-contents', id: 'dynamic-contents'},
  {name: 'Introductory Videos', link: '/introvideos', id: 'intro_videos'},
  {name: 'Helps', link: 'https://justcast.zendesk.com/hc/en-us/categories/360002641372-FAQs', id: 'faq_helps', target: "_blank"},
  {name: "Upgrade", link: '/plans', id: 'plans'},
  {name: "Billing", link: '/billing', id: 'billing'},
  {name: 'Submit a request', link: 'https://justcast.zendesk.com/hc/en-us/requests/new', id: 'submit_request', target: "_blank"},
];

const podcastMenuItems = [
  {name: 'Overview', link: '/shows', id: 'overview'},
  {name: 'Episodes', link: '/shows', id: 'episodes'},
  {name: 'Metrics', link: '/shows', id: 'metrics', advancedPrivateHide: true},
  {name: 'Distributions', link: '/shows', id: 'distributions', isPrivateShowHide: true},
  {name: 'Settings', link: '/shows', id: 'settings'},
  {name: "Episode Sorting", link: '/shows', id: 'episode_sorting'},
  {name: 'Website', link: '/shows', id: 'website_settings', isPrivateShowHide: true},
  // {name: 'Widgets', link: '/shows', id: 'widgets'},
  {name: 'Sharing (beta)', link: '/shows', id: 'sharing'},
  // {name: 'Integrations', link: '/shows', id: 'integrations', isPrivateShowHide: true},
  // {name: 'Tip Jar', link: '/shows', id: 'tip_jar'},
]

const episodeDownloadMenuItems = [
  {name: 'Downloads', link: 'downloads', id: 'downloads', prepend: '/shows'},
]

const episodesTabs = [
  {name: 'Overview', link: '/shows', id: 'episodes'},
  // {name: 'Widget', link: '/shows', id: 'widgets'},
  {name: 'Season', link: '/shows', id: 'seasons'},
  // {name: 'Playlist', link: '/shows', id: 'playlist'},
]

const privateSubscribersTabs = [
  {name: 'Subscribers', link: '/shows', id: 'subscribers'},
  {name: 'Settings', link: '/shows', id: 'private-settings'},
  {name: 'Advanced Controls', link: '/shows', id: 'pragmatic-feed'},
  {name: 'Invitation Email', link: '/shows', id: 'invitation-email'},
  {name: 'Connections', link: '/shows', id: 'podcast-connections'},
  // {name: 'MailerLite', link: '/shows', id: 'mailerlite-groups'}
]

const metricsTabs = [
  {name: 'Overview', link: '/shows', id: 'metrics'},
  // {name: 'Downloads', link: '/shows', id: 'downloads'},
  {name: 'Downloads', link: '/shows', id: 'episodes/report_latest/metrics'},
  // {name: 'Liveview', link: '/shows', id: 'download_liveview', isLiveview: true},
]

const metricsLiveviewTabs = [
  {name: 'Overview', link: '/shows', id: 'metrics'},
  // {name: 'Downloads', link: '/shows', id: 'downloads'},
  {name: 'Downloads', link: '/shows', id: 'episodes/report_latest/metrics'},
  // {name: 'Liveview', link: '/shows', id: 'download_liveview', isLiveview: true},
]

const settingsTabs = [
  {name: 'Settings', link: '/shows', id: 'settings'},
  {name: 'Artwork', link: '/shows', id: 'artwork'},
  {name: 'Advanced', link: '/shows', id: 'advanced_settings'},
  {name: 'Person', link: '/shows', id: 'people_settings'},
  {name: 'Value for Value', link: '/shows', id: 'value_for_value_settings'},
]

const websiteTabs = [
  {name: 'Settings', link: '/shows', id: 'website_settings'},
  {name: 'Customize', link: '/shows', id: 'customize'},
  {name: 'Images', link: '/shows', id: 'website_images'},
  {name: 'Social', link: '/shows', id: 'social'},
]


const episodeTabs = [
  {name: 'Settings', link: '/episodes', id: 'settings', append: '', prepend: '/shows'},
  {name: 'Artwork', link: '/episodes', id: 'artwork', append: '/artwork', prepend: '/shows'},
  {name: 'Value for Value', link: '/episodes', id: 'value_for_value_settings', append: '/value_for_value_settings', prepend: '/shows'},
  {name: 'Chapters', link: '/episodes', id: 'chapters', append: '/chapters', prepend: '/shows'},
  // {name: 'Soundbites', link: '/episodes', id: 'soundbites', append: '/soundbites', prepend: '/shows'},
  // {name: 'Audiogram', link: '/episodes', id: 'podieos', append: '/podieos'},
  // {name: 'Images', link: '/episodes', id: 'images', append: '/images', isPrivateShowHide: true},
]

const r2UploadedEpisodeTabs = [
  {name: 'Settings', link: '/episodes', id: 'settings', append: '', prepend: '/podcasts'},
  {name: 'Assets', link: '/episodes', id: 'assets', append: '/assets', prepend: '/podcasts'},
  {name: 'Value for Value', link: '/episodes', id: 'value_for_value_settings', append: '/value_for_value_settings', prepend: '/shows'},
  {name: 'Chapters', link: '/episodes', id: 'chapters', append: '/chapters', prepend: '/podcasts'},
]

const tipJarTabs = [
  {name: 'Settings', link: '/shows', id: 'tip_jar'},
  {name: 'Tips', link: '/shows', id: 'tips'},
  {name: 'Fees', link: '/shows', id: 'tip_jar_fees'},
]

const episodeSortingTabs = [
  {name: "Episode Sorting", link: '/shows', id: 'episode_sorting'},
  {name: "Episode Number", link: '/shows', id: 'episode_number'},
  // {name: "Subfolder", link: '/shows', id: 'sub_folder'},
]

// Teams
const podcastTabs = [
  {name: "Shows", link: '/dashboard', id: 'shows'},
  {name: "Team Shows", link: '/team_podcasts', id: 'team_podcasts'},
]

const dynamicContentTabs = [
  {name: "Files", link: '/dynamic-contents', id: 'dynamic-contents'},
]

const handleLeftMenuItems = ({podcastMenuItems, advancedPrivate, isPrivateShow, showId}) => {
  const filtedOutAdvancedPrivate = podcastMenuItems.filter((item) => {
    return !(item.advancedPrivateHide === advancedPrivate)
  })

  const filtedOutPrivate = filtedOutAdvancedPrivate.filter((item) => {
    return !(item.isPrivateShowHide === isPrivateShow)
  })

  if(advancedPrivate === true) {
    filtedOutPrivate.push({name: 'Private Subscribers', link: '/shows', id: 'subscribers'})
  }

  const menuItems = filtedOutPrivate.map((item) => {
    return {...item, link: `${item['link']}/${showId}/${item['id']}`}
  })

  // {name: 'Home', link: '/', id: 'home'},

  return [{name: 'Podcasts', link: '/', id: 'home'}, ...menuItems, {name: 'Helps', link: 'https://justcast.zendesk.com/hc/en-us/categories/360002641372-FAQs', id: 'faq_helps', target: "_blank"},];
}

const handleEpisodeTabItems = ({episodeTabs, showId, episodeId, isPrivateShow}) => {
  // episodeMenuItems
  // {name: 'Artwork', link: '/episodes', id: 'artwork', append: 'artwork', prepend: '/shows'},
  // {name: 'Images', link: '/episodes', id: 'images', append: 'images'},

  const filtedOutPrivate = episodeTabs.filter((item) => {
    return !(item.isPrivateShowHide === isPrivateShow)
  })

  const menuItems = filtedOutPrivate.map((item) => {
    let link = '';
    if(item.prepend) {
      link =`${item.prepend}/${showId}`
    }
    if(item.link) {
      link = `${link}${item['link']}/${episodeId}`
    }

    if(item.append) {
      link = `${link}${item.append}`
    }

    return {...item, link}
  })

  return menuItems;
}

const handleEpisodeDownloadMenuItems = ({episodeDownloadMenuItems, showId, episodeId, showName, isPrivateShow}) => {
  // episodeDownloadMenuItems
  //  {name: 'Downloads', link: '/downloads', id: 'downloads', prepend: '/shows'},
  const menuItems = episodeDownloadMenuItems.map((item) => {
    return {...item, link: `${item['prepend']}/${showId}/${item['link']}`}
  })

  return menuItems;
}

const initState = {
  title: null, //{name: "", link: ""},
  subtitle: null, // {name: "", link: ""},
  logo: null, //{src: "", link: ""},
  tabs: [],
  leftSideItems: [],
  userSettings: userMenuItems,
  userModalOpen: false,
  currentPageId: null,
  currentTabId: null,
  rssFeed: null,
  websiteUrl: null,
  landingPageUrl: null,
  isPrivate: null,
  isPrivateShow: null,
  isPrivateShow_forEpisode: null, // We have this one so that we do not need to show the modal button on the header
  passwordProtected: null,
  advancedPrivate: null,
  showId: null,
  episodeId: null,
  showName: null,
  isLiveview: null,
  published_date_sort: null,
  name_sort: null,
  isDirectUpload: false,
  skill_podcast_website: false
}

const MenuReducer = (state, action) => {
  switch(action.type) {
    case 'add':
      return {...state, ...action.payload}
    case 'toggle_user_menu':
      return {...state, userModalOpen: !state.userModalOpen}
    case 'reset':
      return {...initState}
    default:
      return initState
  }
}

const handleAudiopostsSort = dispatch => {
  return ({name, value}) => {
    dispatch({type: "add", payload: {[name]: value}});
  }
}

const add = dispatch => {
  return ({title, subtitle, tabs, logo, leftSideItems, userSettings}) => {
    dispatch({type: 'add', payload: {title, subtitle, tabs, logo, leftSideItems, userSettings}})
  }
}

const setMainPageLeftMenu = dispatch => {
  return ({title, subtitle, currentPageId, skill_podcast_website}) => {
    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems,
      title,
      subtitle,
      currentPageId,
      currentTabId: null,
      rssFeed: null,
      websiteUrl: null,
      landingPageUrl: null,
      isPrivate: true,
      isPrivateShow: true,
      passwordProtected: true,
      advancedPrivate: true,
      skill_podcast_website,
      tabs: [],
    }})
  }
}

const setPodcastPageMenu = dispatch => {
  return ({
    title, subtitle, currentPageId, showId,
    rssFeed, websiteUrl, landingPageUrl, isPrivate,skill_podcast_website,
    passwordProtected, advancedPrivate, isPrivateShow,isLiveview
  }) => {

    // const filtedOutAdvancedPrivate = podcastMenuItems.filter((item) => {
    //   return !(item.advancedPrivateHide === advancedPrivate)
    // })

    // const filtedOutPrivate = filtedOutAdvancedPrivate.filter((item) => {
    //   return !(item.isPrivateShowHide === isPrivateShow)
    // })

    // const menuItems = filtedOutPrivate.map((item) => {
    //   return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    // })

    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId: null,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      isLiveview,
      passwordProtected,
      advancedPrivate,
      skill_podcast_website,
      tabs: []
    }})
  }
}

const setEpisodesMenus = dispatch => {
  return ({
    title, subtitle, currentPageId, currentTabId, showId,
    rssFeed, websiteUrl, landingPageUrl, isPrivate,skill_podcast_website,
    passwordProtected, advancedPrivate, isPrivateShow, isLiveview, isDirectUpload,
    shopifyApp,
  }) => {

    // {name: 'New Episode', link: '/shows', id: 'new-episode'},
    let tabs = []
    if(isDirectUpload) {
      tabs = [...episodesTabs,
        {name: 'New Episode', link: '/shows', id: 'episode/new'},
      ]
    }

    // 2/8/2025 Josh: I don't remember why we are doing this. but decided to pause it for now.
    // if(!shopifyApp) {
    //   tabs = [...tabs, {name: 'Widgets', link: '/shows', id: 'widgets'}]
    // }
    tabs = [...tabs, {name: 'Widgets', link: '/shows', id: 'widgets'}]

    tabs = tabs.map((item) => {
      return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    })

    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      isLiveview,
      passwordProtected,
      advancedPrivate,
      tabs,
      skill_podcast_website,
      isDirectUpload
    }})
  }
}

const setMetricsMenus = dispatch => {
  return ({
    title, subtitle, currentPageId, currentTabId, showId,
    rssFeed, websiteUrl, landingPageUrl, isPrivate, skill_podcast_website,
    passwordProtected, advancedPrivate, isPrivateShow, isLiveview
  }) => {
    let tabs = []
    if(isLiveview) {
      tabs = metricsLiveviewTabs.map((item) => {
        return {...item, link: `${item['link']}/${showId}/${item['id']}`}
      })
    } else {
      tabs = metricsTabs.map((item) => {
        return {...item, link: `${item['link']}/${showId}/${item['id']}`}
      })
    }

    // const menuItems = podcastMenuItems.map((item) => {
    //   return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    // })
    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})
    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      isLiveview,
      passwordProtected,
      advancedPrivate,
      skill_podcast_website,
      tabs
    }})
  }
}

const setSettingsMenus = dispatch => {
  return ({
    title, subtitle, currentPageId,
    currentTabId, showId, rssFeed,
    websiteUrl, landingPageUrl, isPrivate,skill_podcast_website,
    passwordProtected, advancedPrivate, isPrivateShow, isLiveview
  }) => {
    const tabs = settingsTabs.map((item) => {
      return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    })

    // const menuItems = podcastMenuItems.map((item) => {
    //   return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    // })
    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})
    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isLiveview,
      passwordProtected,
      advancedPrivate,
      isPrivateShow,
      skill_podcast_website,
      tabs
    }})
  }
}


const setSortingMenu = dispatch => {
  return ({
    title, subtitle, currentPageId,
    currentTabId, showId, rssFeed,
    websiteUrl, landingPageUrl, isPrivate,skill_podcast_website,
    passwordProtected, advancedPrivate, isPrivateShow, isLiveview, allow_dropbox_subfolders
  }) => {
    const tabs = episodeSortingTabs.map((item) => {
      return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    })

    if(allow_dropbox_subfolders) {
      tabs.push({name: "Subfolder", link: `/shows/${showId}/sub_folder`, id: 'sub_folder'})
    }
    // const menuItems = podcastMenuItems.map((item) => {
    //   return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    // })
    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})
    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isLiveview,
      passwordProtected,
      advancedPrivate,
      isPrivateShow,
      skill_podcast_website,
      tabs
    }})
  }
}

const setDynamicContentMenu = dispatch => {
  return ({
    title, subtitle, currentPageId, currentTabId
  }) => {
    const tabs = dynamicContentTabs
    dispatch({type: 'add', payload: {
      title,
      subtitle,
      currentPageId,
      currentTabId,
      tabs,
      leftSideItems
    }})
  }
}

const setWebsiteMenu = dispatch => {
  return ({
    title, subtitle, currentPageId, currentTabId, showId,
    rssFeed, websiteUrl, landingPageUrl, isPrivate,
    passwordProtected, advancedPrivate, isPrivateShow, isLiveview,skill_podcast_website
  }) => {
    const tabs = websiteTabs.map((item) => {
      return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    })

    // const menuItems = podcastMenuItems.map((item) => {
    //   return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    // })
    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      passwordProtected,
      advancedPrivate,
      isPrivateShow,
      isLiveview,
      skill_podcast_website,
      tabs
    }})
  }
}

const setTipjarMenu = dispatch => {
  return ({
    title, subtitle, currentPageId, currentTabId, showId,
    rssFeed, websiteUrl, landingPageUrl, isPrivate,
    passwordProtected, advancedPrivate, isPrivateShow, isLiveview, skill_podcast_website
  }) => {
    const tabs = tipJarTabs.map((item) => {
      return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    })

    // const menuItems = podcastMenuItems.map((item) => {
    //   return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    // })
    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      passwordProtected,
      advancedPrivate,
      isPrivateShow,
      isLiveview,
      skill_podcast_website,
      tabs
    }})
  }
}

const setPrivateSubscribersrMenu = dispatch => {
  return ({
    title, subtitle, currentPageId, currentTabId, showId,
    rssFeed, websiteUrl, landingPageUrl, isPrivate,
    passwordProtected, advancedPrivate, isPrivateShow, isLiveview, skill_podcast_website
  }) => {
    const tabs = privateSubscribersTabs.map((item) => {
      return {...item, link: `${item['link']}/${showId}/${item['id']}`}
    })

    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems: menuItems,
      title,
      showId,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      passwordProtected,
      advancedPrivate,
      isPrivateShow,
      isLiveview,
      skill_podcast_website,
      tabs
    }})
  }
}

const setEpisodePageMenu = dispatch => {

  return ({
    title,
    subtitle,
    currentPageId,
    currentTabId,
    rssFeed, websiteUrl, landingPageUrl, isPrivate,
    passwordProtected, advancedPrivate, isPrivateShow,
    showName,
    showId,
    isLiveview,
    skill_podcast_website,
    episodeId,
    r2Uploaded
  }) => {

    const tabs = handleEpisodeTabItems({
      episodeTabs: r2Uploaded ? r2UploadedEpisodeTabs : episodeTabs,
      showId,
      episodeId,
      isPrivateShow
    });

    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId,
      showId,
      showName,
      leftSideItems: menuItems,
      title,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      isLiveview,
      passwordProtected,
      advancedPrivate,
      skill_podcast_website,
      tabs,
    }})
  }
}


const setEpisodeDownloadPageMenu = dispatch => {

  return ({title, subtitle, currentPageId, isPrivateShow, showName, showId, episodeId, isLiveview, skill_podcast_website}) => {
    // episodeDownloadMenuItems
    const menuItems = handleEpisodeDownloadMenuItems({
      episodeDownloadMenuItems,
      showId,
      episodeId,
      showName,
      isPrivateShow
    });

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId,
      showId,
      showName,
      leftSideItems: menuItems,
      title,
      subtitle,
      currentPageId,
      currentTabId: null,
      rssFeed: null,
      websiteUrl: null,
      landingPageUrl: null,
      isPrivate: true,
      isPrivateShow: true,
      passwordProtected: true,
      advancedPrivate: true,
      isLiveview,
      skill_podcast_website,
      tabs: [],
    }})
  }
}


const reset = dispatch => {
  return () => {
    dispatch({type: 'reset'})
  }
}

const toggleUserMenu = dispatch => {
  return () => {
    dispatch({type: 'toggle_user_menu'})
  }
}

const setAiContentPageMenu = dispatch => {

  return ({
    title,
    subtitle,
    currentPageId,
    currentTabId,
    rssFeed,
    websiteUrl,
    landingPageUrl,
    isPrivate,
    passwordProtected,
    advancedPrivate,
    isPrivateShow,
    showName,
    showId,
    isLiveview,
    skill_podcast_website,
    episodeId,
    transcriptId
  }) => {
    const tabs = [
      {name: "Transcript", link: `/episodes/${episodeId}/transcripts/${transcriptId}`, id: "segments"},
      {name: "AI Content", link: `/shows/${showId}/episodes/${episodeId}/ai_contents`, id: "ai-contents"},
    ]

    const menuItems = handleLeftMenuItems({podcastMenuItems, isPrivateShow, advancedPrivate, showId})

    dispatch({type: 'add', payload: {
      logo: null,
      episodeId,
      showId,
      showName,
      leftSideItems: menuItems,
      title,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      isLiveview,
      passwordProtected,
      advancedPrivate,
      skill_podcast_website,
      tabs,
    }})
  }
}

const setPodcastsPageMenus = dispatch => {
  return ({title, subtitle, currentPageId, currentTabId, skill_podcast_website}) => {
    dispatch({type: 'add', payload: {
      logo: null,
      episodeId: null,
      leftSideItems,
      title,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed: null,
      websiteUrl: null,
      landingPageUrl: null,
      isPrivate: true,
      isPrivateShow: true,
      passwordProtected: true,
      advancedPrivate: true,
      skill_podcast_website,
      tabs: podcastTabs,
    }})
  }
}


const setTeamPodcastPageMenus = dispatch => {
  return ({
    showId,
    title,
    subtitle,
    currentPageId,
    currentTabId,
    landingPageUrl,
    websiteUrl,
    rssFeed,
    isPrivate,
    isPrivateShow,
    passwordProtected,
    advancedPrivate
  }) => {
    const podcastPageLeftSideItems = [
      {name: 'Podcasts', link: '/team_podcasts', id: 'podcasts'},
      {name: 'Episodes', link: `/team_podcasts/${showId}`, id: 'episodes'},
      {name: 'Settings', link: `/team_podcasts/${showId}/settings`, id: 'settings'},
    ]

    const tabs = [
      {name: "Episodes", link: `/team_podcasts/${showId}`, id: "episodes"},
      {name: "New Episode", link: `/team_podcasts/${showId}/team_episode/new`, id: "new_episode"},
    ]

    dispatch({type: 'add', payload: {
      showId,
      leftSideItems: podcastPageLeftSideItems,
      title,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      passwordProtected,
      advancedPrivate,
      tabs
    }})
  }
}

const setTeamPodcastSettingsPageMenus = dispatch => {
  return ({
    showId,
    title,
    subtitle,
    currentPageId,
    currentTabId,
    landingPageUrl,
    websiteUrl,
    rssFeed,
    isPrivate,
    isPrivateShow,
    passwordProtected,
    advancedPrivate
  }) => {
    const podcastPageLeftSideItems = [
      {name: 'Podcasts', link: '/team_podcasts', id: 'podcasts'},
      {name: 'Episodes', link: `/team_podcasts/${showId}`, id: 'episodes'},
      {name: 'Settings', link: `/team_podcasts/${showId}/settings`, id: 'settings'},
    ]

    const tabs = [
      {name: "Settings", link: `/team_podcasts/${showId}/settings`, id: "settings"},
    ]

    dispatch({type: 'add', payload: {
      showId,
      leftSideItems: podcastPageLeftSideItems,
      title,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      passwordProtected,
      advancedPrivate,
      tabs
    }})
  }
}


const setTeamEpisodePageMenus = dispatch => {
  return ({
    showId,
    episodeId,
    title,
    subtitle,
    currentPageId,
    currentTabId,
    landingPageUrl,
    websiteUrl,
    rssFeed,
    isPrivate,
    isPrivateShow,
    passwordProtected,
    advancedPrivate
  }) => {
    const leftSideItems = [
      {name: 'Podcast', link: `/team_podcasts/${showId}`, id: 'podcast'},
      {name: 'Episode', link: `/team_podcasts/${showId}/team_episodes/${episodeId}`, id: 'episode'},
      // {name: 'Settings', link: `/team_podcasts/${showId}/settings`, id: 'settings'},
    ]

    const tabs = [
      {name: "Settings", link: `/team_podcasts/${showId}/team_episodes/${episodeId}`, id: "settings"},
      {name: "Transcript", link: `/team_podcasts/${showId}/team_episodes/${episodeId}/transcript`, id: "transcript"},
      // {name: "AI Contents", link: `/team_podcasts/${showId}/team_episodes/${episodeId}/ai_contents`, id: "ai_contents"},
    ]

    dispatch({type: 'add', payload: {
      leftSideItems,
      showId,
      episodeId,
      title,
      subtitle,
      currentPageId,
      currentTabId,
      rssFeed,
      websiteUrl,
      landingPageUrl,
      isPrivate,
      isPrivateShow,
      passwordProtected,
      advancedPrivate,
      tabs
    }})
  }
}


export const {Provider, Context} = createDataContext(
  MenuReducer,
  {
    add,
    handleAudiopostsSort,
    toggleUserMenu,
    reset,
    setMainPageLeftMenu,
    setPodcastPageMenu,
    setMetricsMenus,
    setSettingsMenus,
    setWebsiteMenu,
    setEpisodesMenus,
    setEpisodePageMenu,
    setEpisodeDownloadPageMenu,
    setTipjarMenu,
    setSortingMenu,
    setPrivateSubscribersrMenu,
    setAiContentPageMenu,
    setPodcastsPageMenus,
    setTeamPodcastPageMenus,
    setTeamPodcastSettingsPageMenus,
    setTeamEpisodePageMenus,
    setDynamicContentMenu
  },
  initState
)

export default {}