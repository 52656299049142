import React, {useState, useRef, useContext, useEffect} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";
import 'flatpickr/dist/themes/dark.css'
import Flatpickr from 'react-flatpickr'
import {formatDistance, } from 'date-fns'
import {Context as ModalContext} from '../../context/ModalContext'
import {Context as AlertContext} from '../../context/AlertContext'
import { Form, FormGroup, Row, Input, Button, Card, CardBody } from 'reactstrap';
import justcastApi from '../../api/justcast';
// import AudioFilePicker from "./AudioFilePicker"
import AudioFileForm from './AudioFileForm'
import FormGroupInputSelect from '../FormGroupInputSelect';
import {LocationInput} from '../location';
import {EpisodeSeasonSelect} from '../seasons'
import Editor from "./Editor"
import AudioPreviewPlayer from "./AudioPreviewPlayer"
import {directUploadAudio} from '../../api/activestorage';
import UploadStatusLabel from './UploadStatusLabel'
import PublishOptions from './PublishOptions'
import NeedToConvertModal from "./NeedToConvertModal"
import FormGroupInput from '../FormGroupInput';

const EditEpisodeForm = (props) => {
  const {
    id, showId, explicitTypes, episodeTypes,
    seasons, seasonId, handleAddSeason, setSeasonId,
    hosts, audiopostData, isDropbox, slug, isPrivate
  } = props;

  const quillRef = useRef(null);
  const [modal, setModal] = useState(false);
  const modalContext = useContext(ModalContext);
  const {addWithTimeout} = useContext(AlertContext);
  const [keyword, setKeyword] = useState("")
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState(null);
  const [fileId, setFileId] = useState(null);
  const [duration, setDuration] = useState(null);
  const [durationSecond, setDurationSecond] = useState(null);
  const [name, setName] = useState("");
  const [episodeTypeId, setEpisodeTypeId] = useState(null);
  const [explicitTypeId, setExplicitTypeId] = useState(null);
  const [audioDate, setAudioDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [locationName, setLocationName] = useState(null);
  const [locationGeo, setLocationGeo] = useState(null);
  const [episodeNumber, setEpisodeNumber] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadedBlob, setUploadedBlob] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [publishStatus, setPublishStatus] = useState({})
  const [needToConvert, setNeedToConvert] = useState(false);
  const [artworkUrl, setArtworkUrl] = useState(null);
  const [batchNumber, setBatchNumber] = useState(0);

  useEffect(() => {
    if(audiopostData) {
      setName(audiopostData.episode_title);
      setAudioSrc(audiopostData.audio_src);
      setFileName(audiopostData.audio_file_name)
      setFileSize(audiopostData.audio_file_size)
      setFileId(audiopostData.audio_file_id);
      setDuration(audiopostData.duration)
      setDurationSecond(audiopostData.duration_second)
      setEpisodeTypeId(audiopostData.episode_type_id)
      setExplicitTypeId(audiopostData.explicit_type_id)
      setAudioDate(audiopostData.audio_date)
      setEpisodeNumber(audiopostData.episode_number)
      setDescription(audiopostData.description);
      setKeyword(audiopostData.itunes_keywords);
      setLocationName(audiopostData.location_name)
      setLocationGeo(audiopostData.location_geo)
      setIsDraft(audiopostData.is_draft)
      setArtworkUrl(audiopostData.artwork_url)
      setBatchNumber(audiopostData.batch_number);
      setNeedToConvert(false);
      if(audiopostData.is_draft) {
        setPublishStatus({value: true, id: 1, label: "Draft"})
      } else {
        setPublishStatus({value: false, id: 2, label: "Publish"})
      }

    }
  }, [audiopostData])

  const [copyState, setCopyState] = useState(false);
  const hanldeCopyClick = () => {
    setCopyState(true);
    setTimeout(() => {
      setCopyState(false);
    }, 2000)
  }

  const toggle = () => {
    setModal(!modal);
  }

  const handleDurationChanged = (sec, timeStr) => {
    setDuration(timeStr);
    setDurationSecond(sec)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSave();
  }

  const handleSave = () => {
    if(uploadingFile) {
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: ["Can't save while uploading audio file."],
        isOpen: true,
        loading: false
      })
      return;
    }

    const message = quillRef?.current?.unprivilegedEditor?.getHTML();

    const data = {
      name,
      explicit_type_id: explicitTypeId || explicitTypes[1]?.id,
      episode_type_id: episodeTypeId || episodeTypes[0]?.id,
      duration,
      duration_second: durationSecond,
      itunes_keywords: keyword,
      audio_date: audioDate,
      hosts,
      episode_number: episodeNumber,
      need_to_convert: needToConvert,
      artwork_url: artworkUrl,
      client_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      batch_number: batchNumber,
    }

    if(uploadedBlob?.signed_id) {
      data["signed_id"] = uploadedBlob.signed_id
    }
    if(audioFile?.type) {
      data["content_type"] = audioFile.type
    }
    if(message) {
      data['description'] = message;
    }
    if (seasonId && seasonId > 0) {
      data['season_id'] = seasonId;
    }
    if(locationGeo) {
      data['location_geo'] = locationGeo;
    }
    if(locationName) {
      data['location_name'] = locationName;
    }
    data['is_draft'] = isDraft;

    if(!audioFile && !fileId ) {
      // TODO alert modal to tell user need to upload audio file
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: ["Audio file must be included before saving your episode."],
        isOpen: true,
        loading: false
      })
      return;
    }

    justcastApi.put(`/v2/shows/${showId}/audioposts/${id}`, data)
    .then((res) => {
      // console.log(res.data);
      // redirect to the episode page
      // add alert
      const data = res.data;
      const draft_status = data.is_draft;
      let message = "";
      if(draft_status === true) {
        message = "Episode has changed to draft";
      } else if (audioDate > new Date()) {
        message = `Episode has changed to release at ${formatDistance(audioDate, new Date())}`;
      } else {
        message = "Episode is saved as published."
      }

      setAudioSrc(data.audio_src);
      setFileName(data.audio_file_name)
      setFileSize(data.audio_file_size)
      setFileId(data.audio_file_id);
      setDuration(data.duration)
      setDurationSecond(data.duration_second)
      setUploadedBlob(null)

      // addWithTimeout({color: 'warning', message: message, isOpen: true, timeout: 8000});
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: [message],
        isOpen: true,
        loading: false
      })
    })
    .catch((err) => {
      const messages = err.response.data.messages;
      const errorMessages = Object.keys(messages).map((name) => `${name}: ${messages[name].join(",")}`)
      modalContext.addWithMessages({
        color: "primary",
        title: 'Error',
        messages: errorMessages,
        isOpen: true,
        loading: false
      })
    })
  }

  const handleQuillEditorChange = (value) => {
    setDescription(value);
  }

  const handleRemoveFileClicked = () => {
    setFileName("");
    setAudioFile(null);
    setAudioSrc(null);
    setProgress(0);
    setDuration(null);
    setDurationSecond(0)
    setFileId(null);
    setUploadedBlob(null);
    setNeedToConvert(false);
  }

  const handleLocationChange = ({name, lat, lng}) => {
    const geo = `geo:${lat},${lng}`;
    setLocationName(name);
    setLocationGeo(geo)
  }

  const hanleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value);
  }

  const handleEpisodeNumber = (event) => {
    setEpisodeNumber(event.target.value);
  }

  const handleBatchNumber = (event) => {
    setBatchNumber(event.target.value);
  }

  const handleFileChange = (file) => {
    setAudioFile(file);
    if(file) {
      setFileName(file['name'])
      if(name?.length === 0) {
        setName(file['name']);
      }

      setUploadingFile(true);

      const upload = directUploadAudio({
        file: file,
        show_id: showId
      }, (val) => {
        setProgress(val)
      })

      upload.create((err, blob) => {
        if(err) {
          console.log(err)
        } else {
          setUploadedBlob(blob)
          setUploadingFile(false)
        }
      })
    } else {
      setFileName("")
      setUploadingFile(false);
      setNeedToConvert(false);
    }
  }

  const handleExplicitTypeChange = (e) => {
    const value = e.target.value;
    setExplicitTypeId(value);
  }

  const handleEpisodeTypeChange = (e) => {
    const value = e.target.value;
    setEpisodeTypeId(value);
  }

  const handleDatepickerChange = (date) => {
    const _audioDate = date[0];

    if(_audioDate > new Date()) {
      const message = "Do you want this published at a future time/date? This episode won't be released in " + formatDistance(audioDate, new Date())
      addWithTimeout({color: 'warning', message, isOpen: true, timeout: 5000})
    }
    setAudioDate(_audioDate)
  }

  const handleSeasonChange = (id) => {
    setSeasonId(id)
  }

  const handlePublishStatusChange = (e) => {
    setPublishStatus(e)
    setIsDraft(e.value);
  }

  const handleChooseConvert = (convert) => {
    setNeedToConvert(convert);
    setModal(false)
  }

  const removeImageMessages = () => {
    setArtworkUrl(null)
  }

  const handleImageChange = (image) => {
    setArtworkUrl(image?.value || null)
  }

  const jumpToToday = () => {
    const today = new Date()
    setAudioDate(today);
  }

  return (
    <>
      <NeedToConvertModal modal={modal} toggle={toggle} handleChooseConvert={handleChooseConvert}/>
      <Form className="mb-4" onSubmit={handleSubmit}>
        <FormGroup>
          <label>Name</label>
          <Input
            type="text"
            className="form-control"
            name="episode-name"
            placeholder="The title of your new episode"
            value={name}
            onChange={hanleNameChange}
          />
        </FormGroup>
        <Row>
          <div className="col-12 col-md-6">
            <FormGroup>
              <label style={{display: 'flex', justifyContent: 'space-between'}}>
                <span>Publish Date</span>
                <span onClick={jumpToToday} style={{cursor: 'pointer'}}>Jump to Today</span>
              </label>
              <Flatpickr
                className='form-control mb-3'
                data-enable-time
                value={audioDate}
                onClose={handleDatepickerChange}/>
                <small className="form-text text-warning">Pick a date and time for release.</small>
            </FormGroup>
          </div>
          <div className="col-12 col-md-6">
            <FormGroup>
              <label>Publish status</label>
              <div style={{marginBottom: '0.75rem'}}>
                <PublishOptions
                  value={publishStatus}
                  handleChange={handlePublishStatusChange}
                />
              </div>
              <div>
                <small className="form-text text-warning">{isDraft ? "Manually publish when you’re ready." : "Goes live after processing is complete."}</small>
              </div>
            </FormGroup>
          </div>
        </Row>
        <FormGroup>
          <label>Audio File</label>
          {audioSrc ? <AudioPreviewPlayer
            url={audioSrc}
            title={audioFile?.name || fileName}
            fileSize={audioFile?.size || fileSize}
            fileDurationStr={duration}
            handleDurationChanged={handleDurationChanged}
          >
            { uploadingFile ? <UploadStatusLabel progress={progress}/> : !isDropbox && <div className='btn btn-danger btn-sm' onClick={handleRemoveFileClicked}>
              <span className="fe fe-trash"/> Delete
            </div>}
          </AudioPreviewPlayer> :
          <AudioFileForm
            setSrc={setAudioSrc}
            setFile={handleFileChange}
            setModal={setModal}
          />}
          <div style={{marginTop: "8px"}}><small className="text-muted">Supported file types are MP3, WAV, or M4A. Audio files will be encoded up to 128kbps MP3s and the file size cannot exceed 1GB. Variable bit rate files under 128 kbps are not supported.</small></div>
        </FormGroup>
        <hr/>
        <Row>
          <div className="col-12 col-md-6">
            <EpisodeSeasonSelect
              seasons={seasons}
              season_id={seasonId}
              handleSeasonChange={handleSeasonChange}
              handleAddSeason={handleAddSeason}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroup>
              <label>Episode Number</label>
              <Input
                type="text"
                className="form-control"
                name="episode_number"
                placeholder="1, 2, 3..."
                value={episodeNumber ? episodeNumber : ""}
                onChange={handleEpisodeNumber}
              />
            </FormGroup>
          </div>
        </Row>
        <Row>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Explicit type"
              inputName='explicit_type_id'
              optionLabel="label"
              optionId="id"
              mutedText="If you specify true, indicating the presence of explicit content, Apple Podcasts displays an Explicit parental advisory graphic for your podcast.  Podcasts containing explicit material aren’t available in some Apple Podcasts territories."
              options={explicitTypes}
              handleInputChange={handleExplicitTypeChange}
              inputValue={explicitTypeId || explicitTypes[1]?.id}
            />
          </div>
          <div className="col-12 col-md-6">
            <FormGroupInputSelect
              inputType="select"
              inputLabel="Episode type"
              inputName='episode_type_id'
              optionLabel="name"
              optionId="id"
              mutedText="Use Trailer for a short, promotional piece of content that represents a preview of a show.  Use bonus for extra content (for example, behind the scenes information or interviews with the cast)"
              options={episodeTypes}
              handleInputChange={handleEpisodeTypeChange}
              inputValue={episodeTypeId || episodeTypes[0]?.id }
            />
          </div>
        </Row>
        <hr/>
        <FormGroup>
          <label>Description</label>
          <Editor
            quillRef={quillRef}
            value={description}
            handleQuillEditorChange={handleQuillEditorChange}
          />
        </FormGroup>
        <hr/>
        <div className="row">
          <div className="col-12">
            <FormGroup>
              <label>Search Keywords</label>
              <small className="form-text text-muted">Add relevant search keywords about your episode to help people find it.</small>
              <Input
                type="text"
                className="form-control"
                name="keyword"
                placeholder="e.g. business,investment"
                value={keyword || ""}
                onChange={handleKeywordChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <LocationInput
              handleLocationChange={handleLocationChange}
              locationName={locationName}
            />
          </div>
        </div>
        <hr/>
        {props.children}
        <hr/>
        <Card>
          <CardBody>
            <FormGroupInput
              inputLabel='Artwork URL'
              inputType='text'
              inputName="artworkUrl"
              inputValue={artworkUrl ? artworkUrl : ""}
              inputClassName="form-control"
              inputPlaceholder="Put your artwork URL here"
              mutedText="This a feature request for Beeerrrta.  You can paste any image url you host on the internet here, instead of uploading one."
              handleInputChange={handleImageChange}
            >
              <p>If you aren't Beeerrrta, we recommend uploading the episode artwork from <Link to={`/podcasts/${showId}/episodes/${id}/assets`}>here</Link>.</p>
            </FormGroupInput>
            <Row className="align-items-center">

              <div className="col-auto">
                <img src={artworkUrl ? artworkUrl : 'https://via.placeholder.com/150'} className="rounded artwork-img"/>
              </div>
              <div className="col-auto">
                <Button color="danger" size="sm" disabled={artworkUrl ? false : true} onClick={removeImageMessages}>
                  <i className="fe fe-trash"/> Remove
                </Button>
              </div>
            </Row>
          </CardBody>
        </Card>
        <Row>
          <div className="col-12">
            <FormGroup>
              <label>Batch Number</label>
              <Input
                type="number"
                className="form-control"
                name="batch_number"
                placeholder="1, 2, 3..."
                value={batchNumber}
                onChange={handleBatchNumber}
              />
            </FormGroup>
          </div>
        </Row>
        <hr/>
        <div style={{margin: "40px 0 40px 0", display: "flex", justifyContent: "space-between", flexDirection: "row-reverse"}}>
          <div style={{display: 'flex', columnGap: "10px" }}>
            <Link to={`/shows/${showId}/episodes`} className="btn btn-link text-muted">Cancel</Link>
            <div className="btn btn-primary" onClick={handleSave}>Save</div>
          </div>
          {
            !isPrivate && <CopyToClipboard text={`${process.env.REACT_APP_LANDING_PAGE}/shows/${slug}/audioposts/${id}`}
              onCopy={hanldeCopyClick}
            >
              <Button>{copyState ? "Copied" : "Copy Episode URL"}</Button>
            </CopyToClipboard>
          }
        </div>
        {/* <div style={{margin: "40px 0 40px 0", display: "flex", columnGap: "10px", justifyContent: "flex-end"}}>
          <Link to={`/shows/${showId}/episodes`} className="btn btn-link text-muted">Cancel</Link>
          <div className="btn btn-primary" onClick={handleSave}>Save</div>
        </div> */}
      </Form>
    </>
  )
}

export default EditEpisodeForm;