import React, {useContext, useState} from "react";
import Select from 'react-select'
import { Card, CardHeader, CardBody, Button, CardFooter } from 'reactstrap';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
  })
}

export default function ConnectMailerLiteGroup({title, tags, addTag}) {

  const [selectedGroup, setSelectedGroup] = useState({label: "", id: ""});

  const handleAddTag = () => {
    addTag(selectedGroup)
  }

  return (
    <Card>
      <CardHeader>
        <div className="row align-items-center">
          <div className="col">
            <h2 className="card-header-title">{title} Groups</h2>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <Select
          value={selectedGroup}
          options={tags?.map((group) => {
            return {label: group.platform_tag_name, id: group.platform_tag_id, value: group.platform_tag_id}
          })}
          styles={customStyles}
          onChange={(e) => {
            setSelectedGroup(e)
          }}
        />
      </CardBody>
      <CardFooter>
        <Button color="primary" onClick={handleAddTag} disabled={selectedGroup["id"] === ""}>Add group</Button>
      </CardFooter>
    </Card>
  )
}
