import React from "react";
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

const Notification = ({isOpenToast, toggleToast, toastContext}) => {
  return (
    <Toast isOpen={isOpenToast}>
      <ToastHeader toggle={toggleToast}>{toastContext.title}</ToastHeader>
      <ToastBody>
        {toastContext.message}
      </ToastBody>
    </Toast>
  )
}

export default Notification;