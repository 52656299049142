import React from 'react'
import { Card, CardHeader, CardBody, Button } from 'reactstrap';

export default function ConnectedTags({tags, handleDelete}) {

const handleRemoveGroup = (tagId) => {
  handleDelete(tagId)
}

return (
  <Card>
    <CardHeader>
      <h2 className="card-header-title">Connected</h2>
    </CardHeader>
    <CardBody>
      <div className="list-group list-group-flush">
        {
          tags?.map((group) =>
            <div className="list-group-item" key={group.id}>
              <div className="row align-items-center">
                <div className="col ml-n2">{group.name}</div>
                <Button color="danger" size="sm" onClick={() => {
                  handleRemoveGroup(group.id)
                }}>Delete</Button>
              </div>
            </div>
          )
        }
      </div>
    </CardBody>
  </Card>
)
}
