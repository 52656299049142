import React, {useContext, useState, useEffect} from "react";
import justcastApi from '../api/justcast';
import {Context as ScreenContext} from '../context/ScreenContext'
import {Context as MenuContext} from '../context/MenuContext'
import {Context as AuthContext} from '../context/AuthContext'
import { ConnectedTags, Tags, Notification } from '../components/podcast-service-connection'

export default function PodcastServiceConnection(props) {

  const { show_id, id} = props.match.params;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const [loading, setLoading] = useState(true);
  const [isOpenToast, setIsOpenToast] = useState(false)
  const [toastContext, setToastContext] = useState({title: null, message: null});

  const [show, setShow] = useState({});
  const [denied, setDenied] = useState(false);

  const [name, setName] = useState('');

  const [tags, setTags] = useState([]);
  const [connectingTags, setConnectingTags] = useState([]);

  const toggleToast = () => {
    setIsOpenToast(!isOpenToast);
  }

  const autoCloseToast = () => {
    setIsOpenToast(true)
    setTimeout(() => {
      setIsOpenToast(false)
    }, 2000)
  }

  useEffect(() => {

    justcastApi.get(`/v2/shows/${show_id}/service_connections/${id}/service_tag_shows.json`)
    .then((res) => {
      setShow(res.data.podcast);
      setTags(res.data.tags)
      setConnectingTags(res.data.service_tag_shows)
      setName(res.data.service_connection_name)

      setLoading(false)
      menuContext.setPrivateSubscribersrMenu({
        title: 'Connections',
        subtitle: res.data.podcast.name,
        currentPageId: 'subscribers',
        currentTabId: 'podcast-connections',
        showId: show_id,
        landingPageUrl: res.data.podcast.landing_page_url,
        websiteUrl: res.data.podcast.player_page_link,
        rssFeed: res.data.podcast.rss_feed,
        passwordProtected: res.data.podcast.is_password_protected,
        advancedPrivate: res.data.podcast.is_advanced_private_feed,
        isPrivate: res.data.podcast.is_private,
        isPrivateShow: res.data.podcast.is_private_show
      });
    })
    .catch((err) => {
      setLoading(false)
      console.log(err)
    })
  }, [show_id, id])

  const addTag = (group) => {
    justcastApi.post(`/v2/shows/${show_id}/service_connections/${id}/service_tag_shows.json`, {
      platform_tag_name: group.label,
      platform_tag_id: group.value
    })
    .then((res) => {
      const _connectedTags = [res.data, ...connectingTags]
      setConnectingTags(_connectedTags)
      autoCloseToast()
      setToastContext({title: "Success", message: `Connected ${res.data.name}.`})
    })
    .catch((err) => {
      autoCloseToast()
      setToastContext({title: "Failed", message: err.response.data})
    })
  }

  const handleDeleteGroup = (tagId) => {
    justcastApi.delete(`/v2/shows/${show_id}/service_connections/${id}/service_tag_shows/${tagId}.json`)
    .then((res) => {
      const _connectedTags = [...(connectingTags.filter((x) => x.id !== tagId))]
      setConnectingTags(_connectedTags)
      autoCloseToast()
      setToastContext({title: "Success", message: `Disconnected with ${res.data.name}.`})
    })
    .catch((err) => {
      autoCloseToast()
      setToastContext({title: "Failed", message: "Something went wrong"})
      console.log(err)
    })
  }

  if(loading) {
    return null;
  }

  return (
    <div className="container-fluid">
      <Notification
        isOpenToast={isOpenToast}
        toggleToast={toggleToast}
        toastContext={toastContext}
      />
      <div className="row">
        <div className="col-12">
          <Tags
            title={name}
            tags={tags}
            addTag={addTag}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ConnectedTags tags={connectingTags} handleDelete={handleDeleteGroup}/>
        </div>
      </div>
    </div>
  )
}
