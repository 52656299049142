import React, {useContext, useState, useEffect} from "react";
import justcastApi from '../../api/justcast';
import {Context as ScreenContext} from '../../context/ScreenContext'
import {Context as MenuContext} from '../../context/MenuContext'
import CardItem from "./CardItem";
import ShopifyIcon from '../../assets/img/icons/shopify-icon.svg'
import MailerLiteIcon from '../../assets/img/icons/mailerlite-icon.svg'
import ConvertKitIcon from '../../assets/img/icons/convertkit.svg'

const connectionIcons = {
  'shopify': ShopifyIcon,
  'mailerlite': MailerLiteIcon,
  'convertkit': ConvertKitIcon
}

export default function PodcastConnections(props) {
  const showId = props.match.params.show_id;
  const screenContext = useContext(ScreenContext)
  const menuContext = useContext(MenuContext)
  const [loading, setLoading] = useState(true);
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    screenContext.startFetching();

    justcastApi.get(`/v2/shows/${showId}/connections.json`)
    .then((res) => {
      setLoading(false)
      setConnections(res.data.connections)

      menuContext.setPrivateSubscribersrMenu({
        title: 'Connections',
        subtitle: res.data.podcast_title,
        currentPageId: 'subscribers',
        currentTabId: 'podcast-connections',
        showId: showId,
        landingPageUrl: res.data.landing_page_url,
        websiteUrl: res.data.player_page_link,
        rssFeed: res.data.rss_feed,
        passwordProtected: res.data.is_password_protected,
        advancedPrivate: res.data.is_advanced_private_feed,
        isPrivate: res.data.is_private,
        isPrivateShow: res.data.is_private_show
      });
    })
    .catch((err) => {
      setLoading(false)
    })
  }, [showId])

  if(loading) {
    return null
  }

  return (
    <div className="container-fluid">
      {
        connections.map((connection) => <CardItem
          key={connection.title}
          title={connection.title}
          description={connection.description}
          href={connection.href}
          icon={connectionIcons[connection.id]}
        />)
      }
    </div>
  )
}
